import React from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
// import 'react-h5-audio-player/src/styles.scss'
import './audio-big-player.scss'


const BigPlayer = props => {
  return <div className="bigplayer"><AudioPlayer
    src={props.src.url}
    showJumpControls={false}
    showFilledProgress={true}
    layout="horizontal-reverse"
    customAdditionalControls={[]}
    customControlsSection={['MAIN_CONTROLS']}
    customIcons={{
      play: <img src={require('../../assets/images/play.svg')} />
    }}
  /></div>
}

export default BigPlayer
