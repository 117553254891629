require('lightbox2')
window.lity = require('lity');
require('jquery-ui-bundle')
require('jquery.cookie')

$(document).ready(function() {

  $(document).on('click', '[data-lightbox]', lity);

  $('.cookies button').click(function(e){
    $.cookie('zelena_akcija_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies').fadeOut("slow");
  });

  $('.mobile-app button').click(function (e) {
    $.cookie('zelena_akcija_mobile_cookie_closed', 'true', { path: '/', expires: 365 });
    $('.mobile-app').fadeOut("slow");
  });

  $('.hamburger').on('click', function() {
    $('body').toggleClass('navigation_open');
  });

  $('.accessibility').on('click', function(e) {
    e.stopPropagation();
    $(this).toggleClass('active');
  });

  $('.contrast_change').on('click', function() {
    $('body').toggleClass('dark');
    if($.cookie('zelena_akcija_theme')) {
      $.removeCookie('zelena_akcija_theme');
    } else {
      $.cookie('zelena_akcija_theme', 'dark', { expires: 365 });
    }

  });

  $('.links_underline').on('click', function() {
    $('body').toggleClass('links_underlined');
    if($.cookie('zelena_akcija_links')) {
      $.removeCookie('zelena_akcija_links');
    } else {
      $.cookie('zelena_akcija_links', 'links_underlined', { expires: 365 });
    }
  });

  $('.font_change').on('click', function() {
    $('body').toggleClass('font_changed');
    if($.cookie('zelena_akcija_fonts')) {
      $.removeCookie('zelena_akcija_fonts');
    } else {
      $.cookie('zelena_akcija_fonts', 'font_changed', { expires: 365 });
    }
  });

  $('.search').on('click', function(e){
    e.stopPropagation();
    $('.search_box').show();
    $('.search_box').find('input').focus();
  });

  $('.close').on('click', function(){
    $('.search_box').hide();
  });


  // $('.main_nav_item').on('click', function(event) {
  //   event.preventDefault();
  //   $(this).parent().find(".dropdown").toggle();
  //   $(this).toggleClass('active');
  // });

  $('.pop').on('click', function(){
    $('.pop_letter').css("display", "flex");
    $('body').css("overflow", "hidden");
  });

  $('.close_pop').on('click', function(){
    $('.pop_letter').hide();
    $('body').css("overflow", "auto");
  });

  //  This code causes the search box issue
  // $('body').on('click', function() {
  //   $('.accessibility').removeClass('active');
  //   $('.search_box').hide();
  // });




  function processDropdownMenu() {
	if ($(window).width() > 1170) {
		$('.main_nav > li').removeClass('mobile').addClass('desktop');
    $('.main_nav_item').removeClass('active');
    $('.dropdown').removeClass('active');
	} else {
		$('.main_nav > li').removeClass('desktop').addClass('mobile');
	}
  }

  processDropdownMenu();

  $(window).resize(function() {
    processDropdownMenu()
  });

  $(document).on('mouseenter', 'li.desktop', function(e) {
    e.preventDefault();
  	$(this).find('.dropdown').addClass('active');
  }).on('mouseleave', 'li.desktop', function(e) {
    e.preventDefault();
  	$(this).find('.dropdown').removeClass('active');
  });

  $(document).on('click', 'li.mobile .main_nav_item', function(e) {
    e.preventDefault();
  	$(this).parent().find('.dropdown').toggleClass('active');
    $(this).parent().find('.main_nav_item').toggleClass('active');
  });


});
