import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic blockquote | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | styles | cut copy paste | bold italic | bullist numlist | link image media | visualblocks code help'

  if(props.minimal)
    toolbar_buttons = ''

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
      initialValue={props.content}
      init={{
        height: props.height,
        menubar: false,
        inline: props.minimal,
        relative_urls : false,
        style_formats: [
          { title: 'Heading 2', block: 'h2' },
          { title: 'Heading 3', block: 'h3' },
          { title: 'Blockquote', block: 'blockquote' },
          { title: 'Paragraph', block: 'p' }
       ],
       plugins: [
         'image', 'link', 'code', 'lists', 'advlist', 'wordcount', 'media', 'help', 'blockquote',
       ],
       quickbars_selection_toolbar: 'bold italic styles quicklink',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
        toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
