import React from 'react'
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
// import 'react-h5-audio-player/src/styles.scss'
import './audio-player.scss'


const Player = props => {
  return <div className="not_big"><AudioPlayer
    src={props.src.url}
    preload={false}
    showJumpControls={false}
    showFilledProgress={true}
    layout="horizontal"
    customIcons={{
      play: <img src={require('../../assets/images/play.svg')} />
    }}
    customControlsSection={['MAIN_CONTROLS']}
    customAdditionalControls={[]}
    customProgressBarSection={[RHAP_UI.PROGRESS_BAR]}
  /></div>
}

export default Player
