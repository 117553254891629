import React, {useState, useEffect} from 'react'
import axios from 'axios'

const Puzzle = props => {

  const [themes, setThemes] = useState(props.themes)
  const [programs, setPrograms] = useState(props.programs)
  const all_programs = props.all_programs
  // const [leads, setLeads] = useState(props.leads)
  // const [images, setImages] = useState(props.images)
  // const [uploading, setUploading] = useState(99)
  const [buttons, setButtons] = useState(["s", "xl", "xxl", "zt", "bp", "l", "pod"])

  const reset = () => {
    setThemes([])
    setPrograms([])
    // setImages([])
    // setLeads([])
    setButtons(["s", "xl", "xxl", "zt", "bp", "l", "pod"])
  }

  const setProgram = (obj, index) => {
    let new_programs = [...programs]
    new_programs[index] = obj.target.value
    setPrograms(new_programs)
  }

  // const setImage = (obj, index) => {
  //   const formData = new FormData();
  //   formData.append(
  //     "image",
  //     obj.target.files[0],
  //     obj.target.files[0].name
  //   )
  //   formData.append("index", index)
  //   setUploading(index)
  //   axios.post("/api/uploadfile",
  //     formData,
  //     {headers: {'Content-Type': 'multipart/form-data'}
  //   }).then(function (response) {
  //     let new_images = [...images]
  //     new_images[index] = response.data.image
  //     setImages(new_images)
  //     setUploading(99)
  //   });
  // }

  // const setLead = (obj, index) => {
  //   let new_leads = [...leads]
  //   new_leads[index] = obj.target.value
  //   setLeads(new_leads)
  // }

  useEffect(() => {
    if(themes.length === 10) {
      setButtons([])
    } else {
      if(themes.length%2 !== 0) {
        const last = themes[themes.length - 1];
        switch (last) {
          case "s":
          case "zt":
          case "bp":
            setButtons(["xl", "xxl"]);break
          case "l":
            setButtons(["l"]);break
          case "xl":
          case "xxl":
            setButtons(["s", "zt", "bp", "pod"]);break
        }
      } else {
        setButtons(["s", "xl", "xxl", "zt", "bp", "l", "pod"])
      }
    }
  }, [themes]);

  const themeSize = (theme) => {
    switch (theme) {
      case "s":
      case "zt":
      case "bp":
      case "pod":
        return "theme_post col_4";break
      case "l":
        return "theme_post col_6";break
      case "xl":
      case "xxl":
        return "theme_post col_8";break
    }
  }

  const save = () => {
    axios.post("/api/save_homepage", {
      themes: themes,
      programs: programs,
      // leads: leads
    });
  }

  return <div>
    <div className="container row">
      {buttons.map((button) =>
        <button key={button} onClick={() => setThemes([...themes, button])}>Add {button}</button>
      )}
      <button onClick={() => reset()}>Reset all</button>
      {themes.length > 3 &&
        <button onClick={() => save()}>Save</button>
      }
    </div>
    <div className="container row">
      {themes.map((theme, index) =>
        <div key={theme + "_" + index} className={themeSize(theme)}>
          <p>{theme}</p>
          {programs[index] &&
            <a href="#" className="category">{programs[index]}</a>
          }
          {theme !== "zt" && theme !== "bp" && theme !== "pod" &&
            <div>
              <p>
                Izaberi temu:
                <br />
                <select value={programs[index]} onChange={(e) => setProgram(e, index)}>
                  {all_programs.map((program_title, i) =>
                    <option key={i} value={program_title}>{program_title}</option>
                  )}
                </select>
              </p>
            </div>
          }
        </div>
      )}
    </div>
  </div>
}

export default Puzzle
